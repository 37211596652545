const initFaqScripts = () => {
  const faqItems = document.getElementsByClassName('js-toggle-faq');
  for (let i = 0; i < faqItems.length; i += 1) {
    faqItems[i].addEventListener('click', (el) => {
      el.srcElement.closest('.faq_item').classList.toggle('show');
    });
  }
};

const init = () => {
  if (document.querySelector('[data-app-faqs')) {
    initFaqScripts();
  }
};
document.addEventListener('DOMContentLoaded', init);
