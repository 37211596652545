import "../controllers/attendee_app_controllers";

import "../src/custom/Internationalization";
import "../src/plugins/UserPilot";
import "../src/custom/attendee_app";
import "../src/custom/attendee_app/RegistrationSessions";
import { showCountdowns } from "../src/custom/events/countdown_clock";

// Supports component names relative to this directory:
const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");

ReactRailsUJS.useContext(componentRequireContext);

require("jquery-countdown");

const init = () => {
  showCountdowns();
};

document.addEventListener("DOMContentLoaded", init);
