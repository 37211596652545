const init = () => {
  const sessionsSelector = document.querySelector('[data-favorited-sessions]');
  if (sessionsSelector) {
    const sessionIds = sessionsSelector.dataset.favoritedSessions?.split(',') || [];
    sessionIds.filter((id) => id).forEach((sessionId) => {
      document.querySelector(`#link_to_reg_${sessionId}`)?.classList?.add('nofill');
      document.querySelector(`#link_to_reg_${sessionId}_mobile`)?.classList?.add('nofill');
    });
  }
};

document.addEventListener('DOMContentLoaded', init);
