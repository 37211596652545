import { showModal } from '../Modal';

const toggleMenu = (isOpen) => {
  const menu = document.getElementsByClassName('side_menu');
  if (menu) { menu[0].classList.toggle('show', isOpen); }
};

const initSideMenuScripts = () => {
  const menuBtns = document.getElementsByClassName('js-show-menu');
  const closeMenuBtn = document.getElementsByClassName('js-close-menu');

  for (let i = 0; i < menuBtns.length; i += 1) {
    menuBtns[i].addEventListener('click', () => toggleMenu(true));
  }

  for (let i = 0; i < closeMenuBtn.length; i += 1) {
    closeMenuBtn[i].addEventListener('click', () => toggleMenu(false));
  }
};
window.initSideMenuScripts = initSideMenuScripts;

const handleSideMenuLoginBtnClick = () => {
  const loginArea = document.getElementById('aside_login_area');
  const emailField = document.getElementById('email');
  if (loginArea.clientWidth === 0) {
    toggleMenu(false);
    showModal('#login_modal');
  } else emailField.focus();
};
window.handleSideMenuLoginBtnClick = handleSideMenuLoginBtnClick;
